<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入姓名" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="user_id"
          label="会员ID">
      </el-table-column>
      <el-table-column
          prop="name"
          label="昵称"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="user_city"
          label="归属地"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="avatar"
          label="头像"
          align="center"
          width="160">
        <template slot-scope="scope">
          <el-avatar :size="40" shape="square" :src="scope.row.avatar"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="tweeter"
          label="上级"
          align="left"
          width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.tweeter" style="display: flex; align-items: center">
            <el-avatar :size="40" shape="square" :src="scope.row.tweeter.avatar" class="mg-rt-10"></el-avatar>
            <div>{{scope.row.tweeter.name}}<br>{{scope.row.tweeter.user_id}}</div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
              prop="tweeter_up"
              label="上上级"
              align="left"
              width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.tweeter_up" style="display: flex; align-items: center">
            <el-avatar :size="40" shape="square" :src="scope.row.tweeter_up.avatar" class="mg-rt-10"></el-avatar>
            <div>{{scope.row.tweeter_up.name}}<br>{{scope.row.tweeter_up.user_id}}</div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="is_team"
          label="团长/销售"
          align="left"
          width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.is_team==1">专员</div>
          <div v-if="scope.row.is_team==2">分会长</div>
          <div v-if="scope.row.is_team==3">销售</div>
          <div v-if="scope.row.teamInfo">推荐人：{{scope.row.teamInfo.mobile}}({{scope.row.teamInfo.name}})</div>
          <div v-if="scope.row.cityStr">地区：{{scope.row.cityStr}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="grade_name"
          label="会员卡"
          align="center"
          width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.grade_name">
            {{scope.row.grade_name}} <br> {{scope.row.grade_over_time}}到期
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="commission"
          label="余额"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="integral"
          label="积分"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="注册时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="170">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showBox(scope.row)" class="mg-rt-10">设置类型</el-button>
          <el-button type="text" style="color: #67c23a;" size="small" @click="showIntBillBox(scope.row,1)" class="mg-rt-10">积分</el-button>
          <el-button type="text" style="color: #ebb563;" size="small" @click="showIntBillBox(scope.row,2)">余额</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="设置会员类型"
        :visible.sync="showDialog"
        :destroy-on-close="true"
        width="600px">
      <el-form :model="teamInfo" label-position="right" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
        <el-form-item label="会员类型" prop="is_team">
          <el-radio-group v-model="teamInfo.is_team">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">专员</el-radio>
            <el-radio :label="2">分会长</el-radio>
            <el-radio :label="3">销售</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="推荐人" v-if="teamInfo.is_team==1 || teamInfo.is_team==2">
          <el-select v-model="teamInfo.parent_team_id" placeholder="请选择" clearable>
            <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.mobile + '(' + item.name + ')'"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联区域" v-if="teamInfo.is_team==2">
          <el-cascader v-model="teamInfo.team_city" :options="cityTree" clearable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveUser">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
        :title="billInfo.optType==1?'变更积分':'变更余额'"
        :visible.sync="showIntBillDialog"
        :destroy-on-close="true"
        width="600px">
      <el-form :model="billInfo" label-position="right" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="billInfo.type">
            <el-radio :label="1">增加</el-radio>
            <el-radio :label="2">减少</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="billInfo.sum" placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" rows="3" v-model="billInfo.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveChange">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      teamInfo: {
        id: 0,
        is_team: '',
        parent_team_id: '',
        team_city: []
      },
      billInfo: {
        id: 0,
        optType: 0,
        type: '',
        sum: '',
        remark: ''
      },
      showIntBillDialog: false,
      info: false,
      cityTree: false,
      showDialog: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      userList: [],
      isSub: false
    };
  },
  created() {
    this.getUserList()
    this.getList();
    this.getCityTree()
  },
  mounted() {
  },
  computed: {},
  methods: {
    showBox(row) {
      this.teamInfo.id = row.id
      this.teamInfo.is_team = row.is_team
      this.teamInfo.parent_team_id = row.parent_team_id==0 ? '' : row.parent_team_id
      this.teamInfo.team_city = [row.team_province_id,row.team_city_id,row.team_area_id]
      this.showDialog = true
    },
    showIntBillBox(row,type) {
      this.billInfo.optType = type
      this.billInfo.id = row.id
      this.billInfo.sum = ''
      this.billInfo.remark = ''
      this.billInfo.type = ''
      this.showIntBillDialog = true
    },
    getUserList() {
      this.$api.member.memberIndex({page_size: 100, team_type: 23}, res => {
        if (res.errcode == 0) {
          this.userList = res.data.data
        }
      })
    },
    getCityTree() {
      this.$api.merchant.cityTree(res => {
        if(res.errcode==0) {
          this.cityTree = res.data
        }
      })
    },
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords
      }
      this.$api.member.memberIndex(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    saveUser() {
      this.$api.member.memberUpadte(this.teamInfo, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.getList()
          this.getUserList()
          this.showDialog = false
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    saveChange() {
      if(this.isSub) return false;
      if(this.billInfo.type == '') {
        this.fail("请选择操作类型")
        return false
      }
      if(this.billInfo.sum == '') {
        this.fail("请输入变更数量")
        return false
      }
      var params = {
        id: this.billInfo.id,
        sum: this.billInfo.sum,
        type: this.billInfo.type,
        remark: this.billInfo.remark
      }
      this.isSub = true
      if(this.billInfo.optType==1) {
        this.$api.member.memberIntegralChange(params,res => {
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.getList()
            this.showIntBillDialog = false
            this.isSub = false
          } else {
            this.fail(res.errmsg)
          }
        })
      } else {
        this.$api.member.memberBillChange(params,res => {
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.getList()
            this.showIntBillDialog = false
            this.isSub = false
          } else {
            this.fail(res.errmsg)
          }
        })
      }
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
